<template>
	<li v-if="count > 0">
		<div>
			<div
				@click="toggleSelection"
				v-if="node.selected || node.hasChildSelected"
				class="cat-selection-icon filled"
			/>
			<div
				@click="toggleSelection"
				class="cat-selection-icon open"
				v-else
			/>
			<span v-if="!onlyRoot && hasChildren"
				@click="expandCollapse"
				class="caret"
				:class="{ 'caret-down': node.expanded }"
			/>

			<div class="category cat-selectable">
				<span @click="toggleAndExpand" :class="node.selected ? 'cat-selected' : ''">&nbsp;{{ getNumberString(node) }} {{ node.label | fieldToLocale }}</span>
			</div>
		</div>

		<ul v-if="!onlyRoot && node.expanded">
			<tree-selection-item
				v-for="child in children"
				:key="child.id"
				:node="child"
				:tree="tree"
				:prefix="prefix"
				:selected-ids="selectedIds"
			/>
		</ul>
	</li>
</template>

<script>

export default {
	name: 'TreeSelectionItem',

	props: {
		node: {
			type: Object,
			required: true
		},
		tree: {
			type: Object,
			required: true
		},
		onlyRoot: {
			type: Boolean
		},
		rootExpanded: {
			type: Boolean,
			default: false,
			required: false
		},
		prefix: {
			type: String,
			required: true
		},
		selectedIds: {
			type: Array,
			default: () => [],
			required: true
		}
	},

	computed: {
		hasChildren() {
			return this.children.length > 0;
		},
		icon() {
			return this.node.selected ? 'check-square' :
				this.node.hasChildSelected ? ['fas', 'square'] : '';
		},
		children() {
			if (!this.node.children) return [];
			return this.node.children.filter(c => {
				return this.$store.getters[`${this.prefix}/countById`](c.id) > 0;
			});
		},
		count() {
			return this.$store.getters[`${this.prefix}/countById`](this.node.id);
		}
	},

	async created() {
		this.node.expanded = this.rootExpanded;
		if (this.rootExpanded) await this.updateChildren();
	},

	methods: {
		toggleAndExpand() {
			this.toggleSelection();
			if (!this.node.expanded) this.node.expanded = true;
		},
		async expandCollapse() {
			await this.updateChildren();
			if (this.onlyRoot) {
				return;
			}

			this.node.expanded = !this.node.expanded;
		},
		async toggleSelection() {
			await this.updateChildren();
			this.tree.$emit('node-selected', this.node);
		},

		getNumberString(node) {
			if (this.prefix !== 'category' && this.prefix !== 'component') {
				return '';
			}
			return this.$store.getters[`${this.prefix}/numberString`](node.id);
		},

		async updateChildren() {
			let updatedNodes;
			if (this.node.children) {
				if (!this.node.children.find(child => !child.children)) return;
				updatedNodes = await this.$store.dispatch(`${this.prefix}/loadParentWithChildren`, { children: this.node.children, type: this.prefix });
			}
			this.node.children = this.parseChildren(this.node, updatedNodes);

			this.$emit('change', this.node);
		},

		parseItem(item) {
			const selected = !!this.selectedIds.find(selected => selected === item.id);
			let inSelectedPath = false;
			for (const selectedId of this.selectedIds) {
				if (this.$store.getters[`${this.prefix}/pathToRoot`]([ selectedId ]).includes(item.id)) {
					inSelectedPath = true;
					break;
				}
			}
			const parent = {
				label: item.data.title,
				id: item.id,
				selected,
				expanded: false
			};

			if (item.children && item.children.length) parent.children = this.parseChildren(parent, item.children);
			parent.hasChildSelected = inSelectedPath && !selected;

			return parent;
		},

		parseChildren(parent, children) {
			if (!children || !children.length) return [];
			let actualChildren = [];

			for (let child of children) {
				let data = this.parseItem(child);
				data.parent = parent;
				actualChildren.push(data);
			}

			return actualChildren;
		}

	}
};
</script>
