import * as Common from './tree-common';

const state = {

	components: [],
	counts: {},
	entityclassId: ''

};

const getters = {
	roots: (state) => {
		return state.components.filter(c => !c.data.parent);
	},
	byParent: (state) => (id) => {
		return state.components.filter(c => c.data.parent === id && !c.data.disabled);
	},
	byId: (state) => (id) => {
		return state.components.find(c => c.id === id);
	},
	all: (state) => {
		return state.components;
	},

	getLevel: (state, getters) => (id) => {
		return Common.getLevel(getters.roots, 0, id);
	},

	pathToRoot: (state) => (ids) => {
		return Common.pathToRoot(getters.byId(state), ids);
	},

	rootByMainCategory: (state) => (mainCategoryKey) => {
		return getters.roots(state).find(f => f.data.mainCategoryMapping === mainCategoryKey);
	},

	countById: (state) => (id) => {
		return state.counts[id] || 1;
	}
};

const actions = {

	loadEntitesById({ commit }, ids) {
		return Common.loadEntitesById({ commit }, ids);
	},

	loadParentWithChildren({ commit, state }, { children, type }) {
		return Common.loadParentWithChildren({ commit, state }, { children, type });
	},

	ensureRootsLoaded({ commit }, { type }) {
		return Common.ensureRootsLoaded({ commit }, { type });
	},

	loadCounts({ commit, state, rootGetters }) {
		// return Common.loadCounts({ commit, state, rootGetters }, 'component');
	}

};

const mutations = {

	setEcId(state, { id }) {
		state.entityclassId = id;
	},

	setEntry(state, entryToSet) {
		Common.setEntry(state, entryToSet);
	},

	setEntries(state, entriesToSet) {
		Common.setEntries(state, entriesToSet);
	},

	setList(state, components) {
		state.components = components;

		for (let entity of state.components) {
			if (!entity.children) {
				entity.children = [];
			}

			entity.children = getters.byParent(state)(entity.id);
		}
	},

	setCounts(state, counts) {
		state.counts = counts;
	},

	resetCounts(state) {
		state.counts = {};
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
