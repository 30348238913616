export const backendUrl = process.env.VUE_APP_BASE_URL || 'https://demoapi.ecodb.de/bml';
export const NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';
export const CommitSha = process.env.VUE_APP_COMMIT_SHA || 'dev';
export const LogUrl = process.env.VUE_APP_LOG_URL || 'https://demostatic.ecodb.de/csp/';
export const EnableLogin = !!process.env.VUE_APP_ENABLE_LOGIN;

let tmp = backendUrl.replace(/\/$/, '');
export const DOMAIN = tmp.substr(tmp.lastIndexOf('/') + 1);

export const KeycloakInfo = process.env.VUE_APP_KEYCLOAK_INFO
	? JSON.parse(process.env.VUE_APP_KEYCLOAK_INFO)
	: {
		'realm': 'zdb',
		'url': 'http://localhost/auth/',
		'ssl-required': 'none',
		'resource': 'zdb',
		'public-client': true,
		'confidential-port': 0,
		'clientId': 'zdb'
	};
