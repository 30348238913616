<template>
	<div class="tree-selection">
		<ul class="tree-view">
			<tree-selection-item
				v-for="node in nodes"
				:node="node"
				:key="node.id"
				:tree="bus"
				:only-root="onlyRootLevelSelection"
				:root-expanded="rootExpanded"
				@change-selection="changeSelection"
				:prefix="prefix"
				:selected-ids="value"
			/>
		</ul>
	</div>
</template>

<script>
import Vue from 'vue';
import TreeSelectionItem from './TreeSelectionItem.vue';

export default {
	name: 'TreeSelectionRoot',

	components: {
		TreeSelectionItem
	},

	props: {
		prefix: {
			type: String,
			required: true
		},
		value: {
			type: Array,
			default: () => []
		},
		enableMultiSelect: {
			type: Boolean,
			default: () => true
		},
		onlyRootLevelSelection: {
			type: Boolean,
			default: () => false
		},
		rootFilter: {
			type: Array,
			default: () => []
		},
		enableRootSelection: {
			type: Boolean,
			default: () => true
		},
		enableSingleRootSelection: {
			type: Boolean,
			default: () => false
		},
		rootExpanded: {
			type: Boolean,
			default: false,
			required: false
		}
	},

	data() {
		return {
			nodes: [],
			selectedNodeIds: [],
			flattenedNodes: [],
			bus: new Vue()
		};
	},

	computed: {
		model() {
			let data = this.$store.getters[`${this.prefix}/roots`];
			if (this.rootFilter.length > 0) {
				data = data.filter(x => this.rootFilter.indexOf(x.id) > -1);
			}
			if(this.prefix==='component') {
				data.sort((a, b) => {
					if (a.data.order === undefined) return 1;
					if (b.data.order === undefined) return -1;
					return a.data.order - b.data.order;
				});
			}
			return data;
		}
	},

	async created() {
		await this.$store.dispatch(`${this.prefix}/ensureRootsLoaded`, { type: this.prefix });
		await this.$store.dispatch(`${this.prefix}/loadCounts`);

		const processInitial = this.enableMultiSelect || this.value.length === 1;

		for (let node of this.model) {
			const data = this.parseItem(node, processInitial);
			this.nodes.push(data);

			if (!processInitial) {
				continue;
			}

			this.processInitialSelection(node);
		}

		// flatten node array
		this.flattenedNodes = this.flattenArray(this.nodes);

		// Restore selection state
		for (const nodeId of this.selectedNodeIds) {
			let node = this.flattenedNodes.find(x => x.id === nodeId);

			if (node !== null) {
				node.selected = true;

				// this.updateSelection(node, node, node.selected);
			}
		}
		this.updateSecondarySelection();

		this.onNodeSelectedwrap = this.onNodeSelected.bind(this);
		this.bus.$on('node-selected', this.onNodeSelectedwrap);
	},

	beforeDestroy() {
		this.bus.$off('node-selected', this.onNodeSelectedwrap);
	},

	methods: {

		onNodeSelected(node) {
			if (!this.enableRootSelection && typeof node.parent === 'undefined') {
				return;
			}

			this.flattenedNodes = this.flattenArray(this.nodes);
			node.selected = !node.selected;

			// if there is already another root selected, clear the already selected nodes
			// from another subtree
			if (node.selected && this.enableSingleRootSelection) {
				let currentRoot = this.getRootFromNode(node);
				for (let root of this.nodes) {
					if (root.id === currentRoot.id) {
						continue;
					}
					if (root.selected || root.hasChildSelected) {
						// unselect root and its children
						if (root.selected) {
							root.selected = false;
							this.removeSelectedNode(root);
						}
						if (root.hasChildSelected) {
							for (let child of this.deepChildren(root)) {
								if (child.selected) {
									child.selected = false;
									this.removeSelectedNode(child);
								}
							}
						}
					}
				}
			}

			if (!this.enableMultiSelect) {
				// clear any selected nodes before adding the currently selected one
				this.selectedNodeIds = [];
				this.clearSelectionWithException(node);
			} else if (node.selected) {
				//clear the parents and children
				for (let child of this.deepChildren(node)) {
					if (child.selected) {
						child.selected = false;
						this.removeSelectedNode(child);
					}
				}
				for (let parent of this.parents(node)) {
					if (parent.selected) {
						parent.selected = false;
						this.removeSelectedNode(parent);
					}
				}
			}

			if (node.selected) {
				this.addSelectedNode(node);
			} else {
				this.removeSelectedNode(node);
			}
			this.updateSecondarySelection(node);

			this.$emit('input', this.selectedNodeIds);
		},

		changeSelection(node) {
			this.$emit('change', node);
		},

		isInitiallySelected(id) {
			return this.value && !!this.value.find(x => x === id);
		},

		parseItem(item, processInitial) {
			const initialSelection = processInitial && this.isInitiallySelected(item.id);

			const parent = {
				label: item.data.title,
				id: item.id,
				selected: initialSelection,
				expanded: false
			};

			if (item.children && item.children.length) parent.children = this.parseChildren(parent, item.children, processInitial);
			parent.hasChildSelected = false;

			return parent;
		},

		parseChildren(parent, children, processInitial) {
			if (!children || !children.length) return [];
			let actualChildren = [];

			for (let child of children) {
				let data = this.parseItem(child, processInitial);
				data.parent = parent;
				actualChildren.push(data);
			}

			return actualChildren;
		},

		deepChildren(node) {
			let list = [];
			let add = (n) => {
				if (!n.children) return;
				n.children.forEach(c => {
					list.push(c);
					add(c);
				});
			};
			add(node);
			return list;
		},

		parents(node) {
			let list = [];
			let add = (n) => {
				if (!n.parent) return;
				list.push(n.parent);
				add(n.parent);
			};
			add(node);
			return list;
		},

		updateSecondarySelection() {
			let selections = [];
			this.flattenedNodes.forEach(node => {
				node.hasChildSelected = false;
				if (node.selected) selections.push(node);
			});
			selections.forEach(node => {
				if (node.parent) this.markParentSelected(node.parent);
			});
		},

		markParentSelected(node) {
			if (node.hasChildSelected) return;
			if (node.parent) {
				this.markParentSelected(node.parent);
			}
			node.hasChildSelected = true;
		},

		getRootFromNode(node) {
			let root = node;
			while (root.parent && typeof root.parent !== 'undefined') {
				root = root.parent;
			}

			return root;
		},

		removeSelectedNode(node) {
			const idx = this.selectedNodeIds.indexOf(node.id);
			if (idx === -1) return;
			this.selectedNodeIds.splice(idx, 1);
		},

		addSelectedNode(node) {
			this.selectedNodeIds.push(node.id);
		},

		processInitialSelection(node) {
			if (this.isInitiallySelected(node.id)) {
				this.addSelectedNode(node);
			}

			if (!this.onlyRootLevelSelection) {
				if (!node.children) return;
				for (let child of node.children) {
					this.processInitialSelection(child);
				}
			}

		},
		clearSelectionWithException(node) {
			// use node as param to add an exception to the set
			// of elements which should get cleared
			this.clearNodeSelection(node, this.nodes);
		},

		clearNodeSelection(baseNode, nodes) {
			for (let node of nodes) {
				if (node.id !== baseNode.id) {
					node.selected = false;
					node.hasChildSelected = false;
				}
				if (typeof node.children !== 'undefined' && node.children.length > 0) {
					this.clearNodeSelection(baseNode, node.children);
				}
			}
		},

		flattenArray(arr) {
			const flattened = [];
			arr.forEach(item => {
				flattened.push(item);

				if (item.children && Array.isArray(item.children)) {
					flattened.push(...this.flattenArray(item.children));
				}
			});

			return flattened;
		}

	}
};
</script>
