<template>
	<div
		class="search"
		id="BmlSearchPanel"
	>
		<public-search-form @search="updateSearchResults"
			:disabled="loading"
			@scrollToSearchResults="scrollToResults"
		/>
		<b-alert
			:show="error.length > 0"
			variant="danger"
			style="white-space: pre-line;"
		>
			{{ error }}
		</b-alert>

		<div v-if="searchExecuted || loading">
			<b-card class="text-left"
				:no-body="isLoggedIn"
			>
				<template #header>
					{{ $t('results.searchResults') }}
					<b-spinner v-if="loading"
						small
						label="Loading..."
					/>
					<span v-else-if="searchExecuted">
						- {{ searchCount }}
					</span>

				</template>
				<b-tabs card v-if="isLoggedIn && !isHistorySearch">
					<b-tab :title="$t('paiduser.ui.list')" active>
						<search-results ref="searchResults"
							id="BmlSearchResults"
							@search="updateSearchResults"
						/>
					</b-tab>
					<b-tab :title="$t('paiduser.ui.report')">
						<report-tab />
					</b-tab>
					<b-tab v-if="isCompany" :title="$t('paiduser.ui.pdfsAsArchive')">
						<archive-tab />
					</b-tab>
				</b-tabs>
				<search-results ref="searchResults"
					v-else
					id="BmlSearchResults"
					@search="updateSearchResults"
				/>
			</b-card>

		</div>

	</div>
</template>

<script>
import PublicSearchForm from '@/components/PublicSearchForm.vue';
import SearchResults from '@/components/SearchResults.vue';
import ReportTab from '@/components/SearchResult/ReportTab.vue';
import ArchiveTab from '@/components/SearchResult/ArchiveTab.vue';

export default {
	name: 'PublicSearch',

	components: {
		ArchiveTab,
		ReportTab,
		PublicSearchForm,
		SearchResults
	},

	data() {
		return {
			loading: false,
			error: ''
		};
	},

	computed: {
		searchCount() {
			return this.$store.state.search.searchCount;
		},
		searchExecuted() {
			return this.$store.getters['search/wasExecuted'];
		},
		isHistorySearch() {
			return this.$store.getters['search/isHistorySearch'];
		},
		isLoggedIn() {
			return this.$store.getters['auth/token/hasAccountSelected'];
		},
		isCompany() {
			return this.$store.getters['auth/user/hasCompanyType'];
		}
	},

	methods: {
		async updateSearchResults() {
			this.loading = true;
			this.error = '';
			try {
				await this.$store.dispatch('search/search');
			} catch (ex) {
				console.warn(ex);
				if (ex.message.includes('Too Many Requests')) {
					this.error = this.$t('searchForm.errors.tooManyRequests');
				} else {
					this.error = this.$t('searchForm.errors.unknown');
				}
				this.loading = false;
			}
			this.$refs.searchResults.setFields();
			this.loading = false;
		},
		scrollToResults() {
			setTimeout(() => {
				let el = document.getElementById('BmlSearchResults');
				if (el) el.scrollIntoView();
			}, 40);

		}
	}
};
</script>
